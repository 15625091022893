import React, { useRef, useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import Img from "gatsby-image";
import Footer from "src/components/global/Footer.js";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import Body from "src/components/global/Typo/Body.js";
import main from "src/assets/styles/main.js";
import Button from "src/components/global/Button.js";
import ButtonExternal from "src/components/global/ButtonExternal.js";
import { Link } from "gatsby";
import { gsap } from "gsap";
import slugify from "slugify";
import Animate from "src/components/global/Typo/Animate.js";
import AnimateBody from "src/components/global/Typo/AnimateBody.js";
// markup

const Bg = styled.div`
  background-color: ${(props) => (props.bgCol ? props.bgCol : colors.dark)};
  padding-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 15rem;
  }
`;

const Wraper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${distances.topMargin}rem;
  margin-bottom: ${distances.sectionMarginBig}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: ${distances.topMarginMobile}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const DateWrapper = styled.div`
  width: 43.75%;
  /* background-color: red; */
  height: calc(33vw + 40rem);
  position: sticky;
  top: ${distances.gap + 60}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 20%;

    height: calc(16vw + 40rem);
  }
`;
const showBigDate = keyframes`
 0% { top: 17vw; }
 100% { top: 0vw; }
`;
const DateBig = styled.span`
  font-size: 20vw;
  line-height: 17vw;
  display: block;
  color: ${(props) => (props.color ? props.color : colors.white)};
  margin-top: 1vw;
  position: relative;
  top: 17vw;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  animation-name: ${showBigDate};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  &.bottom {
    transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 11vw;
    line-height: 9vw;
  }
`;

const DateTopRow = styled.div`
  height: 17vw;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 9vw;
  }
`;
const DateBottomRow = styled.div`
  /* background-color: blue; */
  height: 17vw;
  overflow: hidden;
  ${DateBig} {
    animation-delay: 0.4s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 9vw;
  }
`;

const YearWrapper = styled.div`
  height: 33rem;
  /* background-color: red; */
  overflow: hidden;
  display: block;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 16rem;
    margin-top: 10rem;
  }
`;
const showYear = keyframes`
 0% { top: 40rem; }
 100% { top: 0; }
`;

const Year = styled.span`
  font-size: ${typographySizes.sm}rem;
  color: ${(props) => (props.color ? props.color : colors.white)};
  line-height: 33rem;
  display: block;
  transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  top: 40rem;
  animation-name: ${showYear};
  animation-duration: 0.5s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    line-height: 16rem;
  }
`;

const NewsWrapper = styled.div`
  width: 56.25%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 80%;
  }
`;

const Title = styled.h1`
  font-size: ${typographySizes.sm}rem;
  line-height: ${typographySizes.smh};
  color: ${(props) => (props.color ? props.color : colors.white)};
  width: 50%;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;
const show = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;
const StyledBody = styled(Body)`
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  margin-top: 50rem;
  width: ${(props) => (props.col2 ? 80 : 40)}%;
  column-gap: ${(props) => (props.col2 ? 30 : 0)}rem;
  column-count: ${(props) => (props.col2 ? 2 : 1)};
  color: ${(props) => (props.color ? props.color : colors.white)};
  @media (max-width: ${mediaQuery.laptop}) {
    width: 80%;
    column-count: 1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  border-radius: ${main.radius}rem;
  margin-top: 50rem;
  opacity: 0;
  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 25rem;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30rem;
`;

const LinksWrapper = styled.div`
  margin-top: 50rem;
  opacity: 0;

  animation-name: ${show};
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
`;

const BottomRow = styled.div`
  margin-top: ${distances.sectionMarginBig}rem;
  display: flex;
  align-items: center;
  margin-bottom: ${distances.sectionMarginBig}rem;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.sectionMarginSmall}rem;
    margin-bottom: ${distances.sectionMarginSmall}rem;
  }
`;

const NextLink = styled(Link)`
  z-index: 10;
  text-decoration: none;
`;

const BidTextNext = styled.span`
  font-size: 20vw;
  color: inherit;
  color: ${(props) => (props.color ? props.color : colors.white)};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const NextLinkText = styled.span`
  color: ${(props) => (props.color ? props.color : colors.white)};
`;

const NextWrapper = styled.div`
  position: relative;
  :hover {
    ${BidTextNext} {
      opacity: 0.1;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

///

const ImgWrapper = styled.div`
  position: fixed;

  cursor: none;

  z-index: 100;

  pointer-events: none;
  opacity: 0;
  top: 100rem;
  left: 0rem;
  transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1),
    top 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.onHover {
    top: 0rem;
    left: 0rem;
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    /* background-color: blue; */
  }
`;

const StyledImgNext = styled(Img)`
  width: 500rem;
  height: 300rem;
  border-radius: ${main.radius}rem;
`;

const TextWrapprNext = styled(ImgWrapper)`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: none;
  &.onHover {
    transition: opacity 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1),
      top 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);

    /* background-color: blue; */
  }
`;

const TextNext = styled.div`
  position: relative;
  background-color: ${(props) => props.colorBg};
  padding: ${distances.gap}rem;
  border-radius: ${main.radius}rem;
  display: block;
  max-width: 300rem;
  transform: translate(-50%, calc(-50% + 300rem));
  z-index: 900;
  span {
    color: ${(props) => props.color};
    font-size: ${typographySizes.s}rem;
  }
`;

const NewsPost = ({ data, pageContext }) => {
  const [onSlider, setOnSlider] = useState(false);

  const boxRef = useRef();
  const boxRefIn = useRef();
  const nextWrapper = useRef();
  const langUrl =
    data.nextPost.locale == "pl" ? "" : `/${data.nextPost.locale}`;
  const allNewsUrl = data.strapiPageNews.Page_name
    ? `${langUrl}/${slugify(data.strapiPageNews.Page_name, {
        lower: true,
        strict: true,
      })}`
    : "";

  const slugTitle = slugify(data.nextPost.Title, {
    lower: true,
    strict: true,
  });
  const nextUrl = langUrl + "/news/" + slugTitle;
  const isBrowser = typeof window !== "undefined";
  let desktop = true;
  if (isBrowser) {
    if (isBrowser && window.innerWidth < 768) {
      desktop = false;
    }
  }
  const movecursor = (e) => {
    // console.log(e.clientY - boxfrom);
    // // console.log(position);

    gsap.to(boxRef.current, {
      duration: 0.5,
      x: e.clientX - 250,
      y: e.clientY - 150,
    });
    gsap.to(boxRefIn.current, {
      duration: 0.7,
      x: e.clientX - 250,
      y: e.clientY - 150,
    });
  };

  const onEnterFn = () => {
    desktop && setOnSlider(true);
  };

  const onLeaveFn = () => {
    desktop && setOnSlider(false);
  };
  return (
    <Bg bgCol={data.strapiPost.Background_color}>
      <Seo
        title={`we3studio : ${data.strapiPost.Title}`}
        description={data.strapiPost.Text_intro}
        image={data.strapiPost.Thumbnail.localFile.publicURL}
      />
      <Nav
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
        catalog={`${pageContext.catalog}/`}
        colorBg={data.strapiPost.Text_dark ? colors.dark : colors.white}
        colorText={data.strapiPost.Text_dark ? colors.white : colors.dark}
        backBtnUrl
      />
      <MainWrapper onPointerMove={movecursor}>
        <Wraper>
          <DateWrapper>
            <DateTopRow>
              <DateBig
                color={data.strapiPost.Text_dark ? colors.dark : colors.white}
              >
                {data.strapiPost.Date.split(".")[0]}
              </DateBig>
            </DateTopRow>
            <DateBottomRow>
              <DateBig
                color={data.strapiPost.Text_dark ? colors.dark : colors.white}
                className="bottom"
              >
                {data.strapiPost.Date.split(".")[1]}
              </DateBig>
            </DateBottomRow>
            <YearWrapper>
              <Year
                color={data.strapiPost.Text_dark ? colors.dark : colors.white}
              >
                (2K{data.strapiPost.Date.split(".")[2].slice(-2)})
              </Year>
            </YearWrapper>
          </DateWrapper>

          <NewsWrapper>
            <Title
              className="intro-text"
              color={data.strapiPost.Text_dark ? colors.dark : colors.white}
            >
              <AnimateBody text={data.strapiPost.Title} />
            </Title>
            <Line
              className="animate"
              color={data.strapiPost.Text_dark ? colors.dark : colors.white}
            />
            {data.strapiPost.Text_intro && (
              <StyledBody
                color={data.strapiPost.Text_dark ? colors.dark : colors.white}
                col2={data.strapiPost.Text_intro.length > 500}
              >
                {data.strapiPost.Text_intro}
              </StyledBody>
            )}
            {data.strapiPost.Link && (
              <LinksWrapper>
                {data.strapiPost.Link.map((linkData) => (
                  <ButtonWrapper>
                    <ButtonExternal
                      target="_blank"
                      href={linkData.Link}
                      color={
                        data.strapiPost.Text_dark ? colors.dark : colors.white
                      }
                    >
                      {linkData.Name}
                    </ButtonExternal>
                  </ButtonWrapper>
                ))}
              </LinksWrapper>
            )}

            <StyledImg
              loading="eager"
              fluid={data.strapiPost.Thumbnail.localFile.childImageSharp.fluid}
            />
            {data.strapiPost.Additional_content && (
              <>
                {data.strapiPost.Additional_content.map((content) => {
                  if (content.strapi_component === "news.text") {
                    return (
                      <StyledBody
                        color={
                          data.strapiPost.Text_dark ? colors.dark : colors.white
                        }
                        col2={content.Text.length > 500}
                      >
                        {content.Text}
                      </StyledBody>
                    );
                  }
                  if (content.strapi_component === "news.img") {
                    return (
                      <StyledImg
                        loading="eager"
                        fluid={content.Img.localFile.childImageSharp.fluid}
                      />
                    );
                  }
                  if (content.strapi_component === "news.link") {
                    return (
                      <LinksWrapper>
                        <ButtonExternal
                          href="#"
                          color={
                            data.strapiPost.Text_dark
                              ? colors.dark
                              : colors.white
                          }
                        >
                          {content.Name}
                        </ButtonExternal>
                      </LinksWrapper>
                    );
                  }
                })}
              </>
            )}
          </NewsWrapper>
        </Wraper>
        <Line color={data.strapiPost.Text_dark ? colors.dark : colors.white} />
        <BottomRow>
          <Button
            to={allNewsUrl}
            color={data.strapiPost.Text_dark ? colors.dark : colors.white}
          >
            {data.nextPost.locale == "pl"
              ? "          zobacz wysztkie newsy"
              : `see all news`}
          </Button>
          <NextWrapper
            ref={nextWrapper}
            onMouseEnter={onEnterFn}
            onMouseLeave={onLeaveFn}
          >
            <NextLink to={nextUrl}>
              <NextLinkText
                color={data.strapiPost.Text_dark ? colors.dark : colors.white}
              >
                <ImgWrapper ref={boxRef} className={onSlider && "onHover"}>
                  <StyledImgNext
                    loading="eager"
                    fluid={
                      data.nextPost.Thumbnail.localFile.childImageSharp.fluid
                    }
                  />
                </ImgWrapper>
                <TextWrapprNext
                  ref={boxRefIn}
                  className={onSlider && "onHover"}
                >
                  <TextNext
                    colorBg={
                      data.strapiPost.Text_dark ? colors.dark : colors.white
                    }
                    color={
                      data.strapiPost.Text_dark ? colors.white : colors.dark
                    }
                    className={onSlider && "onHover"}
                  >
                    <span>{data.nextPost.Title}</span>
                  </TextNext>
                </TextWrapprNext>
                <BidTextNext
                  color={data.strapiPost.Text_dark ? colors.dark : colors.white}
                >
                  next
                </BidTextNext>
              </NextLinkText>
            </NextLink>
          </NextWrapper>
        </BottomRow>
      </MainWrapper>
      <Footer
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        colorBg={data.strapiPost.Text_dark ? colors.dark : colors.white}
        colorText={data.strapiPost.Text_dark ? colors.white : colors.dark}
        lang={pageContext.locale}
      />
    </Bg>
  );
};

export default NewsPost;

export const IndexQuery = graphql`
  query NewsPost($locale: String!, $id: String!, $nextId: String!) {
    nextPost: strapiPost(id: { eq: $nextId }) {
      Title
      locale
      Thumbnail {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
    }
    strapiPost(locale: { eq: $locale }, id: { eq: $id }) {
      Title
      Date(formatString: "DD.MM.YYYY")
      Background_color
      Text_dark
      Text_intro
      Thumbnail {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
      Link {
        Link
        Name
      }
      Additional_content {
        ... on STRAPI__COMPONENT_NEWS_IMG {
          strapi_component
          Img {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
        ... on STRAPI__COMPONENT_NEWS_LINK {
          strapi_component
          Name
          Link
        }
        ... on STRAPI__COMPONENT_NEWS_TEXT {
          strapi_component
          Text
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
